const static_items = {
  car: [
    // فقط در بازار خودرو آیدی های زیر ، در واقع آیدی برنچ هستند چون خواسته شده بود که در قسمتی که ساب کستگوری های مختلف را نمایش می دهیم  لیست برنچ ها نمایش داده
    // شود و در دیتابیس اصلی بازار خودرو چندیدن برنچ دارد اما فقط یک ساب کتگوری دارد

    {
      id: 15001,
      label: "ایران خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/irankhodro.png",
    },
    {
      id: 15002,
      label: "سایپا",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/sipa.png",
    },
    {
      id: 15003,
      label: "مدیران خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/mvm.png",
    },
    {
      id: 15004,
      label: "کرمان موتور",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/kermanmotors.png",
    },

    {
      id: 15005,
      label: "آمیکو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/amico.png",
    },
    {
      id: 15006,
      label: "کیا",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/kia.png",
    },
    {
      id: 15007,
      label: "بی ام و",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/BMW.png",
    },
    {
      id: 15008,
      label: "فونیکس",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/fownix.png", //
    },
    {
      id: 15009,
      label: "هایما",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/Haima.png",
    },
    {
      id: 15010,
      label: "فردا موتورز",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/fardamotor.png",
    },
    {
      id: 15011,
      label: "دانگ فنگ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/dangfeng.png",
    },
    {
      id: 15012,
      label: "سیتروئن",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/citroen.png", //
    },
    {
      id: 15013,
      label: "گروه بهمن",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/bahmanmotors.png",
    },
    {
      id: 15014,
      label: "اس دبلیو ام",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/swm.png",
    },
    {
      id: 15015,
      label: "هیوندای",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/Hyundai.png",
    },
    {
      id: 15016,
      label: "سانگ یانگ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/ssangyong.png",
    },
    {
      id: 15017,
      label: "رنو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/Renault.png",
    },
    {
      id: 15018,
      label: "یوآز",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/uaz.png",
    },

    {
      id: 15019,
      label: "خودمکث موتوررو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/maxmotors.png", //
    },
    {
      id: 15020,
      label: "جک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/jac.png",
    },
    {
      id: 15021,
      label: "لاماری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/lamari.png", //
    },
    {
      id: 15022,
      label: "بسترن",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/faw.png",
    },
    {
      id: 15023,
      label: "لکسوس",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/lexus.png",
    },
    {
      id: 15024,
      label: "تویوتا",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/Toyota.png",
    },
    {
      id: 15025,
      label: "پورشه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/Porsche.png",
    },
    {
      id: 15026,
      label: "فولکس",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/folex.png", //
    },
    {
      id: 15027,
      label: "میتسوبیشی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/Mitsubishi.png",
    },
    {
      id: 15028,
      label: "دیار خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/diarkhodro.png", //
    },
    {
      id: 15029,
      label: "دایون",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/dayun.png", //
    },
    {
      id: 15030,
      label: "لیفان",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car/lifan.png",
    },
  ],
  car_accessories: [
    {
      id: 5001,
      label: "خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/خودرو.png",
    },
    {
      id: 5002,
      label: "بدنه خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/بدنه خودرو.png",
    },
    {
      id: 5003,
      label: "داخل خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/داخل خودرو.png",
    },
    {
      id: 5004,
      label: "الکترونیک خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/الکترونیک خودرو.png",
    },
    {
      id: 5005,
      label: "دستگاه پخش و باند",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/دستگاه پخش و باند.png",
    },
    {
      id: 5006,
      label: "لوازم مصرفی خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/لوازم مصرفی خودرو.png",
    },
    {
      id: 5007,
      label: "لوازم جانبی خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/لوازم جانبی خودرو.png",
    },
    {
      id: 5008,
      label: "تیونینگ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/تیونینگ.png", //
    },
    {
      id: 5009,
      label: "موتور سیکلت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/موتور سیکلت.png",
    },
    {
      id: 5010,
      label: "لوازم جانبی موتور سیکلت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/لوازم موتور سیکلت.png",
    },
    {
      id: 5011,
      label: "لوازم کامیون",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/لوازم کامیون.png",
    },
    {
      id: 5012,
      label: "ماشین آلات سنگین",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/ماشین آلات سنگین.png",
    },
    {
      id: 5013,
      label: "لوازم یدکی خودرو",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/car_accessories/لوازم جانبی خودرو.png",
    }
  ],
  iron: [
    {
      id: 8001,
      label: "میلگرد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/میلگرد.png",
    },
    {
      id: 8002,
      label: "تیرآهن",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/تیرآهن.png",
    },
    {
      id: 8003,
      label: "لوله",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/لوله.png",
    },
    {
      id: 8004,
      label: "ورق",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/ورق.png",
    },
    {
      id: 8005,
      label: "قوطی و پروفیل",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/قوطی و پروفیل.png",
    },
    {
      id: 8006,
      label: "ناودانی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/ناودانی.png",
    },
    {
      id: 8007,
      label: "سپری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/سپری.png",
    },
    {
      id: 8008,
      label: "نبشی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/نبشی.png",
    },
    {
      id: 8009,
      label: "مفتولی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/iron/مفتول.png",
    },
  ],
  food: [
    {
      id: 1001,
      label: "میوه و سبزیجات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/میوه و سبزیجات.png",
    },
    {
      id: 1002,
      label: "آجیل و خشکبار",
      info: "آخرین نرخ ها و نوسانات بازار   ",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/آجیل و خشکبار.png",
    },
    {
      id: 1003,
      label: "حبوبات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/غلات و حبوبات.png",
    },
    {
      id: 1004,
      label: "قنادی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/خشکبار، دسر و شیرینی.png",
    },
    {
      id: 1005,
      label: "صبحانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/سوپر مارکت.png",
    },
    {
      id: 1006,
      label: "گیاهان دارویی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/گیاهان دارویی.png",
    },
    {
      id: 1007,
      label: "مواد شوینده",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1008,
      label: "لبنیات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1009,
      label: "مواد پروتئینی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1010,
      label: "آبلیمو، سرکه و ترشیجات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1011,
      label: "غذای آماده و کنسرویجات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1012,
      label: "ادویه و چاشنی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1013,
      label: "نوشیدنی ها",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1014,
      label: "رب",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1015,
      label: "سس",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1016,
      label: "تنقلات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1017,
      label: "کالاهای اساسی و خوار و بار",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    },
    {
      id: 1018,
      label: "غلات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/food/وسایل روزمره زندگی.png",
    }
  ],
  home_appliances: [
    {
      id: 3001,
      label: "شستشو و نظافت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/شستشو و نظافت.png"
    },
    {
      id: 3002,
      label: "تهویه سرمایش و گرمایش",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/سرمایشی و گرمایشی.png"
    },
    {
      id: 3003,
      label: "لوازم خانگی برقی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/لوازم آشپزخانه.png"
    },
    {
      id: 3004,
      label: "سایر لوازم خانگی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/سایر لوازم خانگی.png"
    },
    {
      id: 3005,
      label: "آشپزخانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/آشپزخانه.png"
    },
    {
      id: 3006,
      label: "خواب و حمام",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/خواب و حمام.png"
    },
    {
      id: 3007,
      label: "سیستم های امنیتی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/سیستم های امنیتی.png"
    },
    {
      id: 3008,
      label: "تجهیزات خانه هوشمند",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/تجهیزات خانه هوشمند.png"
    },
    {
      id: 3009,
      label: "لوازم جانبی لوازم خانگی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/لوازم جانبی لوازم خانگی.png"
    },
    {
      id: 3010,
      label: "لوازم خرازی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/home_appliances/صوتی و تصویری.png"
    }
  ],
  computer: [

    {
      id: 2001,
      label: "لپ تاپ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/لپ تاپ و لوازم جانبی.png",
    },
    {
      id: 2002,
      label: "کیف پول سخت افزاری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/کیف پول سخت افزاری.png",
    },
    {
      id: 2003,
      label: "کامپیوتر",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/مانیتور.png",
    },
    {
      id: 2004,
      label: "تجهیزات شبکه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/تجهیزات شبکه.png",
    },
    {
      id: 2005,
      label: "تجهیزات اداری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/تجهیزات اداری.png",
    },
    {
      id: 2006,
      label: "تجهیزات ذخیره‌سازی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/تجهیزات ذخیره سازی.png",
    },
    {
      id: 2007,
      label: "لوازم جانبی لپ تاپ و کامپیوتر",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/گجت.png",
    },
    {
      id: 2008,
      label: "دستگاه ماینینگ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/ماینینگ.png",
    }
  ],
  digital: [
    {
      id: 6001,
      label: "گوشی موبایل",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/موبایل.png",
    },
    {
      id: 6002,
      label: "هندزفری هدست هدفون",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/هدست و هدفون.png",
    },
    {
      id: 6003,
      label: "تبلت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/تبلت.png",
    },
    {
      id: 6004,
      label: "کتاب خوان و کاغذ دیجیتال ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/کتاب خوان.png",
    },
    {
      id: 6005,
      label: "GPS",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/gps.png",
    },
    {
      id: 6006,
      label: "سیم کارت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/سیم کارت.png",
    },
    {
      id: 6007,
      label: "لوازم جانبی موبایل و تبلت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/computer/لپ تاپ و لوازم جانبی.png",
    },
    {
      id: 6008,
      label: "ساعت و مچ بند هوشمند ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/ساعت هوشمند.png",
    },
    {
      id: 6009,
      label: "لوازم جانبی ساعت هوشمند",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/سیستم صوتی.png",
    },
    {
      id: 6010,
      label: "سایر لوازم جانبی گوشی و تبلت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/سیستم صوتی.png",
    },
    {
      id: 6011,
      label: "دوربین",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/digital/سیستم صوتی.png",
    }
  ],
  tools: [
    {
      id: 7001,
      label: "ابزار دستی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/chisel.png",
    },
    {
      id: 7002,
      label: "ابزار برقی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/برق و الکترونیک (2).png",
    },
    {
      id: 7003,
      label: "ابزار و تجهیزات باغبانی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار های باغبانی و کشاورزی.png",
    },
    {
      id: 7004,
      label: "ابزار لوله کشی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزارهای تاسیسات و آبرسانی.png",
    },
    {
      id: 7005,
      label: "ابزار مکانیکی و بنزینی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار مکانیکی و بنزینی.png",
    },
    {
      id: 7006,
      label: "ابزار نجاری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار های باغبانی و کشاورزی.png",
    },
    {
      id: 7007,
      label: "ابزار دقیق و اندازه گیری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار های اندازه گیری.png",
    },
    {
      id: 7008,
      label: "تجهیزات صنعتی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار های جوش و برش.png",
    },
    {
      id: 7009,
      label: "ابزار خراطی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار خراطی.png",
    },
    {
      id: 7010,
      label: "تجهیزات الکترونیک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/تجهیزات الکترونیک.png",
    },
    {
      id: 7011,
      label: "تجهیزات هیدرولیک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/تجهیزات هیدرولیک.png",
    },
    {
      id: 7012,
      label: "ابزار و تجهیزات بنایی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/tools/ابزار نقاشی ساختمان.png",
    }
  ],
  paper: [
    {
      id: 4001,
      label: "کاغذ و مقوا",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/retailMarkets/بازار کاغذ.png",
    },
  ],
  baby: [
    {
      id: 10001,
      label: "لوازم گردش و سفر نوزاد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لوازم گردش و سفر نوزاد.png",
    },
    {
      id: 10002,
      label: "بهداشت و حمام نوزاد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/بهداشت و حمام نوزاد.png",
    },
    {
      id: 10003,
      label: "وسایل خواب نوزاد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/وسایل خواب نوزاد.png",
    },
    {
      id: 10004,
      label: "لوازم غذا خوری نوزاد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لوازم غذا خوری نوزاد.png",
    },
    {
      id: 10005,
      label: "ایمنی، مراقبت نوزاد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/ایمنی، مراقبت نوزاد.png",
    },
    {
      id: 10006,
      label: "لباس کودک و نوزاد",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لباس کودک و نوزاد.png",
    },
    {
      id: 10007,
      label: "سرگرمی و آموزش کودک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/سرگرمی و آموزش کودک.png",
    },
    {
      id: 10008,
      label: "غذای کودک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/غذای کودک.png",
    },
    {
      id: 10009,
      label: "لوازم ترئینی اتاق کودک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/ایمنی، مراقبت نوزاد.png",
    }
  ],
  materials: [
    {
      id: 13001,
      label: "شیرآلات ساختمانی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/شیرآلات ساختمانی.png",
    },
    {
      id: 13002,
      label: "یراق آلات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/یراق آلات.png",
    },
    {
      id: 13003,
      label: "تجهیزات الکتریکی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/تجهیزات الکتریکی.png",
    },
    {
      id: 13004,
      label: "تجهیزات دستشویی و حمام",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/تجهیزات دستشویی و حمام.png",
    },
    {
      id: 13006,
      label: "محصولات شیمیایی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/محصولات شیمیایی.png",
    },
    {
      id: 13005,
      label: "مصالح ساختمانی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/مصالح ساختمان.png",
    },
    {
      id: 13007,
      label: "تزئینات ساختمانی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/تزئینات ساختمانی.png",
    },
    {
      id: 13008,
      label: "دکوراسیون فضای عمومی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/دکوراسیون فضای عمومی.png",
    },
    {
      id: 13009,
      label: "تجهیزات شهری و ترافیک",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/تجهیزات شهری و ترافیک.png",
    },
    {
      id: 13010,
      label: "منابع و مخازن",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/منابع و مخازن.png",
    },
    {
      id: 13011,
      label: "تجهیزات ساختمانی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/materials/تجهیزات آشپزخانه.png",
    },
  ],
  entertainment: [
    {
      id: 9001,
      label: "وسایل ورزشی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/entertainment/وسایل ورزشی.png",
    },
    {
      id: 9002,
      label: "اسباب بازی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/entertainment/اسباب بازی.png",
    },
    {
      id: 9003,
      label: "سرگرمی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/entertainment/سرگرمی.png",
    }
  ],
  health: [
    {
      id: 12001,
      label: "تجهیزات پزشکی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/health/تجهیزات پزشکی.png",
    },
    {
      id: 12002,
      label: "گجت های تندرستی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/health/گجت های تندرستی.png",
    },
    {
      id: 12003,
      label: "محصولات طبی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/health/محصولات طبی.png",
    },
    {
      id: 12004,
      label: "بهداشت و مراقبت",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/health/بهداشت و مراقبت.png",
    },
    {
      id: 12005,
      label: "دارو و مکمل ها",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/health/مکمل ها و ویتامین های خوراکی.png",
    },
    {
      id: 12006,
      label: "تجهیزات آزمایشگاهی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/health/مکمل های گیاهی.png",
    }
  ],
  clothing: [
    {
      id: 11001,
      label: "پوشاک مردانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/پوشاک مردانه.png",
    },
    {
      id: 11002,
      label: "پوشاک زنانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/پوشاک زنانه.png",
    },
    {
      id: 11003,
      label: "پوشاک کودکان",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/پوشاک کودکان.png",
    },
    {
      id: 11004,
      label: "زیورآلات مردانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/زیورآلات.png",
    },
    {
      id: 11005,
      label: "زیورآلات زنانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/زیورآلات.png",
    },
    {
      id: 11006,
      label: "عینک آفتابی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/عینک آفتابی.png",
    },
    {
      id: 11007,
      label: "کیف و کفش",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/کیف و کفش.png",
    },
    {
      id: 11008,
      label: "ساعت مچی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/کمربند و بند شلوار.png",
    },
    {
      id: 11009,
      label: "اکسسوری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/کلاه اسپرت.png",
    },
    {
      id: 11010,
      label: "ست هدیه زنانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/ست هدیه زنانه.png",
    },
    {
      id: 11011,
      label: "ست هدیه مردانه",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/ست هدیه.png",
    },
    {
      id: 11012,
      label: "اکسسوری مو   ",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/کادو ولنتاین.png",
    },
    {
      id: 11013,
      label: "ست لباس زن و شوهر",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/ست لباس زن و شوهر.png",
    },
    {
      id: 11014,
      label: "پارچه و منسوجات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/پارچه و منسوجات.png",
    },
    {
      id: 11015,
      label: "لباس محلی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/لباس محلی.png",
    },
    {
      id: 11016,
      label: "ساک و چمدان",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/clothing/کلاه اسپرت.png",
    },
  ],
  culture: [
    {
      id: 14001,
      label: "کتاب و مجلات",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
    },
    {
      id: 14002,
      label: "رسانه های صوتی و تصویری",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/رسانه های صوتی و تصویری.png",
    },

    {
      id: 14004,
      label: "نرم افزار",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/نرم افزار.png",
    },
    {
      id: 14005,
      label: "ادوات موسیقی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/ادوات موسیقی.png",
    },
    {
      id: 14006,
      label: "نوشت افزار",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/نوشت افزار.png",
    },
    {
      id: 14007,
      label: "محصولات مجازی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/محصولات مجازی.png",
    },
    {
      id: 14008,
      label: "اشیا قدیمی و کلکسیونی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/اشیا قدیمی و کلکسیونی.png",
    },
    {
      id: 14009,
      label: "محصولات مذهبی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/محصولات مذهبی.png",
    },
    {
      id: 14010,
      label: "تجهیزات کمک آموزشی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/تجهیزات کمک آموزشی.png",
    },
    {
      id: 14011,
      label: "جشن ها",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/جشن ها.png",
    },
    {
      id: 14012,
      label: "محصولات چاپی",
      info: "آخرین نرخ ها و نوسانات بازار",
      image:
        "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/محصولات مجازی.png",
    },
  ],
  // decoration: [
  //   {
  //     id: 17001,
  //     label: "لوستر و نور پردازی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لوازم گردش و سفر نوزاد.png",
  //   },
  //   {
  //     id: 17002,
  //     label: "فضای منزل",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/بهداشت و حمام نوزاد.png",
  //   },
  //   {
  //     id: 17003,
  //     label: "لوازم تزئینی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/وسایل خواب نوزاد.png",
  //   },
  //   {
  //     id: 17004,
  //     label: "کاغذ دیواری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لوازم غذا خوری نوزاد.png",
  //   },
  //   {
  //     id: 17005,
  //     label: "استیکر و پوستر",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/ایمنی، مراقبت نوزاد.png",
  //   },
  //   {
  //     id: 17006,
  //     label: "رومیزی، رانر",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لباس کودک و نوزاد.png",
  //   },
  //   {
  //     id: 17007,
  //     label: "درخت و تزئینات کریسمس",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/سرگرمی و آموزش کودک.png",
  //   }
  // ],
  // petshop: [
  //   {
  //     id: 16001,
  //     label: "درمان و سلامت حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لوازم گردش و سفر نوزاد.png",
  //   },
  //   {
  //     id: 16002,
  //     label: "پوشاک حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/بهداشت و حمام نوزاد.png",
  //   },
  //   {
  //     id: 16003,
  //     label: "لوازم بهداشتی حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/وسایل خواب نوزاد.png",
  //   },
  //   {
  //     id: 16004,
  //     label: "لوازم نگهداری و بازی حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لوازم غذا خوری نوزاد.png",
  //   },
  //   {
  //     id: 16005,
  //     label: "غذای حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/ایمنی، مراقبت نوزاد.png",
  //   },
  //   {
  //     id: 16006,
  //     label: "وسایل آموزشی حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/لباس کودک و نوزاد.png",
  //   },
  //   {
  //     id: 16007,
  //     label: "لوازم جانبی حیوانات",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/سرگرمی و آموزش کودک.png",
  //   },
  //   {
  //     id: 16008,
  //     label: "تجهیزات آکواریوم ",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/baby/غذای کودک.png",
  //   }
  // ],
  // travel: [
  //   {
  //     id: 15001,
  //     label: "تجهیزات کوهنوردی و صخره نوردی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15002,
  //     label: "لوازم جانبی کمپینگ و کوه نوردی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15002,
  //     label: "لوازم شکار",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15004,
  //     label: "پمپ آب سفری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15005,
  //     label: "چشم بند خواب",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15006,
  //     label: "یخدان و یخچال مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15007,
  //     label: "اجاق گاز مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15008,
  //     label: "گالن مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15009,
  //     label: "صندلی مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15010,
  //     label: "طناب مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15011,
  //     label: "پشه بند",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15012,
  //     label: "کپسول گاز مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15013,
  //     label: "بخاری سفری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15014,
  //     label: "ساج",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15015,
  //     label: "ظروف مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15016,
  //     label: "سرشعله سفری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15017,
  //     label: "میز سفری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15018,
  //     label: "ننو و تخت آویز",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15019,
  //     label: "ابزار چندکاره سفری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15020,
  //     label: "تراول ماگ",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15021,
  //     label: "تشک بادی و مسافرتی",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15022,
  //     label: "سیخ و توری کباب",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15023,
  //     label: "بادبزن سفری",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15024,
  //     label: "چتر",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  //   {
  //     id: 15025,
  //     label: "کیف غذا",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
  // ],
  // other: [
  //   {
  //     id: 18001,
  //     label: "تست",
  //     info: "آخرین نرخ ها و نوسانات بازار",
  //     image:
  //       "https://static.tgju.org/views/default/images/tgju/app/retailMarket/culture/کتاب و مجلات.png",
  //   },
    
  // ],
};

export default static_items;
